import React from 'react'
import '../styles.css'

function RaportHelp () {
  return(
    <div>
      <video
        className = "raports-video-help"
        controls = { true }
        src='img/ohjeet/rapsat-ohje-v1.mp4'/>
      <p>OK, no siitä piti tulla 3 min., sitten tapahtui jotain frameraten kanssa 😳</p>
      <ol className="rapsat-ol">
        <li>Lataa koneelle CSV-tiedosto Booklystä. Se löytyy Appointments välilehden alta.</li>
        <li>Valitse Created any time, </li>
        <li>Valitse haluamasi ajankohta. Klikkaa Export to CSV...</li>
        <li>Anna olla kaikki täpät valinnassa</li>
        <li>Vaihda Delimiter puolipisteeksi / Semicolon</li>
        <li>Klikkaa Export to CSV</li>
        <li>Poistu Booklystä ja klikkaa täällä | Valitse tiedostot |</li>
        <li>Voit valita useamman tiedoston, jos haluat yhdistää ne. Esim. Kemppi + Rautatienkadun hieronnat</li>
        <li>Täytä alle vuosi ja kuukausi, jolle haluat nimetä tiedostot</li>
        <li>Lataa tiedosto ja klikkaa Lähetä</li>
        <li>Ohjelma muodostaa jokaisesta työntekijästä oman raportin CVS muodossa</li>
        <li>Voit muokata provisiota ja kiinteän vuokran määrää sopimuksen mukaan</li>
      </ol>
    </div>
  )
}
export default RaportHelp


