// this function modifies csv data to array

function csvToArr(stringVal, splitter) {
  //change long key value to simpler
  const [keys, ...rest] = stringVal.split('"').join('')
    .split('Onko sinulla lahjakortti (nro), muut lisätiedot').join('Tiedot')
    .split('Appointment date').join('Date')
    .split('Lisätietoja hierojalle').join('Tiedot')
    .split('Työntekijä').join('Palveluntarjoaja')
    .split(' (Kuka tahansa)').join('')
    .split('Customer name').join('Customer')
    .split('reipas,').join('reipas: true,')
    // clean € chars away
    .split('€').join('')
    // .replace(/(\d),(?=\d)/g, '$1.') // replace commas to dots between numbers
    .trim()
    .split('\n')
    .map((item) => item.split(splitter))

  const formedArr = rest.map((item) => {
    const object = {}
    keys.forEach((key, index) => (object[key] = item.at(index)))
    return object
  })
  return formedArr
}
export default csvToArr