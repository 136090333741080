// find individual names from array
const findUniqueNames = (arr) => {
  let uniqueNames = []
  arr.forEach( (item) => {
    // somehow heading for staff differs in two Bookly plugins
    if(item.Palveluntarjoaja || item.Työntekijä){
      let name = item.Palveluntarjoaja ? item.Palveluntarjoaja : item.Työntekijä
      if(uniqueNames.length > 0 && uniqueNames.filter(uName => uName === name ).length > 0){
        return
      }
      else {
        if(uniqueNames.length === 0)
          uniqueNames = [name]
        else{
          uniqueNames.push(name)
        }
      }
    }

  } )
  return uniqueNames
}

export default findUniqueNames