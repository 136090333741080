import React from 'react'
import RaportListItem from './RaportListItem'

function RaportList({ uniqueWorkers, provisio, jsonValue, title, setLockProvision }){

  return(
    <>
      <h4>Klikkaa alla työntekijän nimeä ladataksesi raportin</h4>
      <p>Voit muuttaa kunkin provisio %:ia sekä kiinteätä vuokraa.<br/>
            Tämän hetkisissä sopimuksissa se on joko</p>
      <ul>
        <li>25% + kiinteä vuokra 0</li>
        <li>10% + kiinteä vuokra 220 €</li>
      </ul>
      <div className="staffReport-list mp-mt-30">
        <div style={{ width: '150px' }}> Työntekijä</div>
        <div>Provisio (%)</div>
        <div>Kiinteä vuokra</div>
        <div style={{ textAlign: 'right' }}>Yhteensä</div>
      </div>
      <RaportListItem
        uniqueWorkers = {uniqueWorkers}
        provisio = {provisio}
        jsonValue = {jsonValue}
        title = {title}
        setLockProvision={setLockProvision}
      />
    </>
  )

}
export default RaportList