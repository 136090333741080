import React from 'react'
import convertJsonToCSV from './convertJsonToCSV'
import downloadString from './downloadString'

function StaffButton({ worker, jsonWorker, title }){

  return(
    <>
      {/* button to download CSV (with staff name) */}
      <button className="txt-download"
        style={{ width: '150px' }}
        onClick= { () => {
          downloadString(
            // conver json data to CSV (to be downloaded)
            convertJsonToCSV(jsonWorker), 'csv', `Fysio Up - tyoraportti-${worker}-${title}.csv`
          )}}>
        {worker}
      </button>
    </>

  )
}

export default StaffButton

