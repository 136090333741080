import { useSelector } from 'react-redux'
import round from '../../../functions/round'

// modify json data to needs of Fysio Up
function filteredJson(provisionOfWorker, fixedPrice, worker, jsonValue, cvsHeader) {
  console.log(' in filter: ', jsonValue)
  var reg = /^\d*\.?\d+$/
  let totalPrice = 0
  let turnover = 0

  // vars for customers with contract
  let laukkanen = 0
  let leivsaneeraus = 0


  // filter worker if it is defined
  const filterByWorker = (item) => {
    if(!worker){return true}
    else if(item.Palveluntarjoaja === worker) return true
    else if (worker === 'all') return true
    else return false
  }

  // json item for array
  const jsonItem = jsonValue.filter(item => filterByWorker(item)).map(function(x){
    let provision = Number(provisionOfWorker)/100

    // virike = 0 | no effect
    let hinta = 0
    let hintaOriginal
    let virike = 1

    // products are same as in database in cards App. They must be equal to product names in CVS exported in booking App (Bookly or so.)
    const products =  useSelector(state => state.users && state.products)
      // filtered by id of company (Fysio Up)
      .filter(product => product.locationId === '649be348eab23a1e30731051' )

    // find prices for products
    if (x.Palvelu)
    {
      try{
        hinta = products.filter(product => product.productName === x.Palvelu)[0].productValue
        hintaOriginal = hinta
      } catch(e){
        // if product does not exist, price will be 0
        console.log(x.Palvelu + 'Tuote on hinnoittelun ulkopuolella, joten sen hinnaksi jää 0')
      }

      // tiedot is infofield in cvs. If it exists, change it to lowerCase
      let tiedot = x.Tiedot ? x.Tiedot.toLowerCase() : ''

      // first change price if there is some deal
      if(tiedot.includes('reipas')){ hinta = hinta - 8}

      // if string test is in info field. We assume that it is just testing, so skipping...
      if(tiedot.includes('test')){ hinta = 0}

      // client didnt arrive
      if(tiedot.includes('ei saapunut')){ hinta = 0}

      // ------ 1. find out if tiedot string is valid json. If it is use it in the first instance.

      // key words to be affected -> adding " -marks to make it valid json data.
      const convertedJson = tiedot
        .split('”').join('"')
        .split('reipas').join('"reipas"')
        .split('epassi').join('"epassi"')
        .split('e-passi').join('"epassi"')
        .split('smartum').join('"smartum"')
        .split('edenred').join('"edenred"')
        .split('lahjakortti').join('"lahjakortti"')
        .split('sarjakortti').join('"sarjakortti"')
        .split('hinta').join('price ')
        .split('price').join('"price"')
        .split('message').join('"message"')
        .split('msg').join('"message"')
        .split('fysioup').join('"fysioup"')
        .split('fysio up').join('"fysioup"')
        .split('fup').join('"fysioup"')

      // find out if string is valid json
      const isJson = () => {
        try{
          JSON.parse('{' + convertedJson + '}')
        } catch(e) {
          return false
        }
        return true
      }

      // if it is, parse it
      if(isJson(convertedJson)){
        // parse json from string
        const tiedotJson = JSON.parse('{' + convertedJson + '}')

        // custom price for client
        if(tiedotJson.price && Number(tiedotJson.price) > 0) {
          hinta = tiedotJson.price
        }
        // calculating provision and virike amounts using json data
        if(tiedotJson.epassi && Number(tiedotJson.epassi) > 0) {
          provision -= round(tiedotJson.epassi/hinta,4)
          virike = round(0.95 + tiedotJson.epassi/hinta * 0.05,4)
        }
        // smartum
        if(tiedotJson.smartum && Number(tiedotJson.smartum) > 0) {
          provision -= round(tiedotJson.smartum/hinta,4)
          virike = round(0.95 +  Number(tiedotJson.smartum/hinta) * 0.05,4)
        }
        // edenred
        if(tiedotJson.edenred && Number(tiedotJson.edenred) > 0) {
          provision -= round(tiedotJson.edenred/hinta,4)
          virike = round(0.95 + tiedotJson.edenred/hinta * 0.05, 4)
        }
        // lahjakortti
        if(tiedotJson.lahjakortti && Number(tiedotJson.lahjakortti) > 0) {
          provision -= round(tiedotJson.lahjakortti/hinta,4)
        }
        // sarjakortti
        if(tiedotJson.sarjakortti && Number(tiedotJson.sarjakortti) > 0) {
          provision -= round(tiedotJson.sarjakortti/hinta,4)
        }
        // fysio up (raha yrityksen tilille, ei työntekijälle)
        if(tiedotJson.fysioup && Number(tiedotJson.fysioup) > 0) {
          provision -= round(tiedotJson.fysioup/hinta,4)
        }
      }


      // ------ 2. if there is no valid json, caluclate provision and virike using key words

      // virike (5% provikka heille)
      else if((tiedot.includes('epassi') || tiedot.includes('passi') || tiedot.includes('e-pas') ||tiedot.includes('smart') || tiedot.includes('edenr') || tiedot.includes('fysioup') || tiedot.includes('fupin') || tiedot.includes('fup') || tiedot.includes('fysio')) && !tiedot.includes('lasku')) {
        virike = 0.95
        provision = round(-(1 - provision), 4)
      }
      // sarjakortti | lahjakortti
      else if(tiedot.includes('sarjakort') || tiedot.includes('000') || reg.test(tiedot) || tiedot.includes('202' || tiedot.includes('sk\t')) || tiedot === 'sk' || tiedot === 'lk' || tiedot.includes('lahja') || tiedot.includes('levisan') || tiedot.includes('saneeraus') ){
        provision = round(-(1 - provision),4)
      }
      // laukkanen
      else if(tiedot.includes('laukka') || x.Customer==='Pasi Laukkanen' || x.Customer==='Anna Kihlakaski'){
        provision = round(provision - 0.5, 4)
        if(x.Customer==='Pasi Laukkanen' || x.Customer==='Anna Kihlakaski'){
          laukkanen += hintaOriginal
          console.log('worker in laukka: ', worker)
          tiedot = '(Code: Laukkanen 100 | ' + x.Customer + ') ' + tiedot
        } else {
          tiedot = '(Code: Laukkanen 50/50 | ' + x.Customer + ') ' + tiedot
          laukkanen += hintaOriginal/2
        }
      }
      if(tiedot.includes('levis') || tiedot.includes('levi san') || tiedot.includes('levi-san')){
        leivsaneeraus += hintaOriginal
        tiedot = '(Code: Levisaneeraus 100 | ' + x.Customer + ') ' + tiedot
      }

      // kemppi
      if(x.Palvelu === 'Kemppi hieronta'){
        provision = round(provision - 0.05, 4)}

      // place values to json keys
      x.Hinta = hinta
      totalPrice = totalPrice + round((Number(hinta) * Number(provision) * Number(virike)),2)
      turnover = turnover + round(Number(hinta),2)
      x.Yht = round(Number(hinta) * Number(provision) * Number(virike),2).toString().split('.').join(',')
      if(provision){provision = round(provision,4).toString().split('.').join(',')}
      virike = virike.toString().split('.').join(',')

      // one json line = CVS row
      return {
        ID: x.ID,
        Date: x.Date,
        Palveluntarjoaja: x.Palveluntarjoaja ? x.Palveluntarjoaja : x.Työntekijä,
        palvelu: x.Palvelu,
        Tiedot: tiedot,
        Hinta: hinta,
        Provisio: provision,
        Virike: virike,
        Yht: x.Yht
      }
    }

    else return cvsHeader

  })
  totalPrice += (Number(fixedPrice ? fixedPrice : 0))

  // Lowest lines in CVS | turnover, fixed price, total amount
  return [... jsonItem, {
    ID: 'Liikevaihto',
    Date: '',
    Palveluntarjoaja: '',
    palvelu: '',
    Tiedot: '',
    Hinta: turnover,
    Provisio: '',
    Virike: '',
    Yht: ''
  },
  {
    ID: 'Kiinteä vuokra',
    Date: '',
    Palveluntarjoaja: '',
    palvelu: '',
    Tiedot: '',
    Hinta: '',
    Provisio: '',
    Virike: '',
    Yht: fixedPrice ? fixedPrice : 0
  },
  {
    ID: 'Yhteensä:',
    Date: '',
    Palveluntarjoaja: 'Vuokran osuus: ',
    palvelu: round(turnover * provisionOfWorker/100 + (Number(fixedPrice ? fixedPrice : 0)) ,2),
    Tiedot: 'Maksettavaa (- tulos on hyvitettävää)',
    Hinta: '',
    Provisio: '',
    Virike: '',
    Yht: round(totalPrice, 2)
  },
  {
    ID: 'Laukkanen:',
    Date: '',
    Palveluntarjoaja: '',
    palvelu: '',
    Tiedot: '',
    Hinta: '',
    Provisio: '',
    Virike: '',
    Yht: round(laukkanen, 2)
  },
  {
    ID: 'Levisaneeraus:',
    Date: '',
    Palveluntarjoaja: '',
    palvelu: '',
    Tiedot: '',
    Hinta: '',
    Provisio: '',
    Virike: '',
    Yht: round(leivsaneeraus, 2)
  }
  ]
}

export default filteredJson