// convert json to CSV

function convertJsonToCSV(objArray) {
  var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  var str = ''
  for (var i = 0; i < array.length; i++) {
    let line = '', comma = '', j = 0

    for (var index in array[i]) {
      // if (line !== '') line += ','
      if(j < index.length) comma = ';'
      line += array[i][index] + comma
      j ++
    }
    str += line + '\r\n'
  }
  return str
}
export default convertJsonToCSV