import React, { useEffect, useState } from 'react'
import filteredJson from './filteredJson'
import round from '../../../functions/round'
import StaffButton from './StaffButton'
const RaportListItem = ({ uniqueWorkers, provisio, jsonValue, title, setLockProvision }) => {

  const cvsHeader = { ID: 'ID',  Date: 'Aika', Palveluntarjoaja: 'Palveluntarjoaja',  palvelu: 'Palvelu', Tiedot: 'Tiedot', Hinta: 'Hinta', Provisio: 'Provisio', Virike: 'Virike', Yht: 'Yht.' }

  // for filttering row by worker or if it is the last item with Fixed Price
  let totalAmount = 0
  let turnoverTotal = 0
  let provisionTotal = 0
  let laukkanenTotal = 0
  let levisaneerausTotal = 0
  let allWorkers = []
  const buttons = uniqueWorkers.map(function(worker,i){

    const[provisioWorker, setProvisoWorker] = useState(provisio)
    const [fixedPrice, setFixedPrice] = useState(0)


    useEffect(() => {setProvisoWorker(provisio)},[provisio])

    const provisionHandler = (e) => {
      // update value if number
      setProvisoWorker(isNaN(e.target.value) ? provisioWorker : e.target.value)
      setLockProvision(true)
    }
    // json data of worker
    let jsonWorker = [cvsHeader].concat(filteredJson(provisioWorker, fixedPrice, worker, jsonValue, cvsHeader))
    allWorkers = allWorkers.concat(jsonWorker)

    // pick up total amount from json data (balance of money to worker / Company)
    let workerTotal = round(jsonWorker[jsonWorker.length -3].Yht,2)
    let turnover = round(jsonWorker[jsonWorker.length -5].Hinta,2)
    let provision = round(jsonWorker[jsonWorker.length -3].palvelu,2)
    let laukkanen = round(jsonWorker[jsonWorker.length -2].Yht,2)
    let levisaneeraus = round(jsonWorker[jsonWorker.length -1].Yht,2)

    // amount of all staff
    totalAmount += workerTotal
    turnoverTotal += turnover
    provisionTotal += provision
    laukkanenTotal += laukkanen
    levisaneerausTotal += levisaneeraus

    return(
      <div key = {worker} >
        <div className = "staffReport-list">

          <StaffButton
            title={title}
            worker={worker}
            jsonWorker = {jsonWorker}
          />

          <div>
            <input style={{ width: '50px' }} value={provisioWorker} onChange={provisionHandler}></input>  %
          </div>
          <div>
            <input style={{ width: '50px'  }} value={fixedPrice} onChange={ (e) => {setFixedPrice(!isNaN(e.target.value) ? e.target.value : fixedPrice)}}></input>€
          </div>
          <div style={{ width: '100px', textAlign: 'right' }}><b>{workerTotal} €</b></div>
        </div>
        {uniqueWorkers.length - 1 === i &&
        <div>

          <hr/>
          <div style={{ textAlign: 'right' }}><b>Yhteensä: {round(totalAmount,2)} €</b></div>
          <div style={{ textAlign: 'right' }}><b>Liikevaihto: {round(turnoverTotal,2)} €</b></div>
          <div style={{ textAlign: 'right' }}><b>Provisiotulot: {round(provisionTotal,2)} €</b></div>
          <div style={{ textAlign: 'right' }}><b>Laukkanen yht.: {round(laukkanenTotal,2)} €</b></div>
          <div style={{ textAlign: 'right' }}><b>levisaneeraus yht.: {round(levisaneerausTotal,2)} €</b></div>
          <div className = "buttons-row" style={{ justifyContent: 'flex-start', gap: '10px' }}>
            <StaffButton
              title={title + ' all workers'}
              worker= 'Kaikki'
              jsonWorker = {allWorkers}
            />
            <StaffButton
              title={title + ' Levisaneeraus'}
              worker= 'Levisaneeraus'
              jsonWorker = {allWorkers.filter(row => row.Tiedot.includes('Code: Levi'))}
            />
            <StaffButton
              title={title + ' Laukkanen'}
              worker= 'Laukkanen'
              jsonWorker = {allWorkers.filter(row => row.Tiedot.includes('Code: Laukka'))}
            />
          </div>

        </div>
        }
      </div>
    )})
  return buttons
}
export default RaportListItem